import { ConnectorStatusConfigs } from '@common/data/connectorStatus';

const connectorSettingExists = (status: string | null): boolean => {
  if (!!status && !!ConnectorStatusConfigs[status]) return true;
  return false;
};

export const getConnectorStatusClassName = (status: string | null): string => {
  if (!!status && connectorSettingExists(status)) {
    return ConnectorStatusConfigs[status].className;
  }
  return ConnectorStatusConfigs['default'].className;
};

export const getConnectorStatusTextKey = (status: string | null): string => {
  if (!!status && connectorSettingExists(status)) {
    return ConnectorStatusConfigs[status].textKey;
  }
  return ConnectorStatusConfigs['default'].textKey;
};

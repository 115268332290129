import React, { Component } from 'react';
import Modal from 'react-modal';
import { withNamespaces } from 'react-i18next';
import { straight_sculpt } from '@common/assets/images';

export interface ChargingSpeedModalProps {
  t: (arg0: string, arg1?: any) => string;
  isOpen: boolean;
  closeModal: () => void;
}
export interface ChargingSpeedModalState {}

class ChargingSpeedModal extends Component<ChargingSpeedModalProps, ChargingSpeedModalState> {
  public render(): React.ReactNode {
    const { t, isOpen, closeModal } = this.props;
    return (
      <Modal isOpen={isOpen} className="charging-speed-modal">
        <div className="charging-speed-modal-header">
          <span data-id="charging-speed-modal-title" className="charging-speed-modal-title">
            {t('r_charging_speeds')}
          </span>
          <img src={straight_sculpt} className="charging-speed-modal-title-background-image"></img>
        </div>
        <div className="charging-speed-modal-body" data-id="charging-speed-modal-body">
          <div className="charging-speed-modal-body-description">{t('r_charging_speeds_desc')}</div>
        </div>
        <div className="charging-speed-modal-footer" data-id="charging-speed-modal-footer">
          <button
            data-id="charging-speed-modal-close-btn"
            className={'charging_speed_modal_close_btn'}
            onClick={() => closeModal()}>
            {t('close_button')}
          </button>
        </div>
      </Modal>
    );
  }
}

export default withNamespaces('translations')(ChargingSpeedModal as any);
export { ChargingSpeedModal };

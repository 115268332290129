export function getVehicleChargingTime(start: number, end: number): string {
  if (isNaN(start) || isNaN(end)) {
    return '0';
  }
  let difference = Math.abs(Number(start) - Number(end));
  const hours = Math.floor(difference / 3600000);
  difference -= hours * 3600000;
  let minutes = Math.floor(difference / 60000);
  difference -= minutes * 60000;
  const seconds = Math.floor(difference / 1000);
  if (seconds >= 30) {
    minutes = minutes + 1;
  }
  if (hours.toString() !== '0') {
    return `${hours}:${('0' + minutes).slice(-2)}`;
  } else {
    return `${('0' + minutes).slice(-2)}`;
  }
}

export enum COUNTRY_NUMBERS {
  UK = '0345 600 6290',
  ES = '961 789 133',
  IT = '06 9101 6709',
  DE = '0221 903 9795',
  FR = '01 5566 1737',
  BE = '32 24 822787',
  NL = '31 205 044676',
  US = '1-800-34-Fleet',
  CA = '1-800-34-Fleet',
}

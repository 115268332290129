export const getAppLanguage = (): string => {
  const fordSyncLanguage: string = window.fordSync && window.fordSync.getLanguage();
  const browserLanguage: string = window.navigator.language;
  let language: string = fordSyncLanguage || browserLanguage;
  if (language.indexOf('-') !== -1) {
    const languageStrings: string[] = language.split('-');
    language = `${languageStrings[0]}_${languageStrings[1].toUpperCase()}`;
  }
  return language;
};

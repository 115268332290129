import { vehicleProgramInformation } from '@common/enums';
import { AddressItems, StationLocationObject } from '@common/interfaces';
import LocalStorageUtils from '@common/utilities/localStorage';
import { t } from 'i18next';

export default class NavigationService {
  private buildStationAddress(locationObject: StationLocationObject): string {
    const addressArray: any[] = [];

    addressArray.push(this.validateAddressItem(locationObject.address1));
    addressArray.push(this.validateAddressItem(locationObject.address2));
    addressArray.push(this.validateAddressItem(locationObject.city));
    addressArray.push(this.validateAddressItem(locationObject.state));
    addressArray.push(this.validateAddressItem(locationObject.postalCode));
    addressArray.push(this.validateAddressItem(locationObject.country));

    let stationAddress = addressArray.join(' ');
    stationAddress = encodeURIComponent(stationAddress.replace(/\s+/g, ' ').trim().toLowerCase());
    const vehicleType: string = vehicleProgramInformation[LocalStorageUtils.getDeviceType()];
    if (vehicleType === 'P702_BEV' || vehicleType === 'CX727') {
      return `nav:routeto?lat=${locationObject.streetLat}&lon=${
        locationObject.streetLon
      }&description=${t('charge_station_selected')}`;
    }
    return `nav:routeto?address=${stationAddress}`;
  }

  public startNavigation(locationObject: StationLocationObject): void {
    const stationAddressRoute = this.buildStationAddress(locationObject);

    window.location.href = stationAddressRoute;
  }

  private validateAddressItem(item: AddressItems): string | number | void {
    if (!!item) return item;
  }
}

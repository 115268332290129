import { plug_in_light_icon, plug_in_dark_icon } from '@common/assets/images';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';

export interface UnPlugButtonProps {
  t: (arg0: string) => string;
  history: any;
}

class UnPlugButton extends Component<UnPlugButtonProps> {
  constructor(props: UnPlugButtonProps) {
    super(props);
  }
  render(): React.ReactNode {
    const { t } = this.props;
    const Unplug = t('unplug').toLocaleLowerCase();
    return (
      <div className="plugInContainer">
        <button
          className="button--secondary"
          data-id="unplug-btn"
          style={{ textTransform: 'none' }}
          onClick={() => {}}>
          <img
            src={plug_in_light_icon}
            alt="Plug Icon"
            className="plug-icon light-icon"
            data-id="plug icon"
          />
          <img
            src={plug_in_dark_icon}
            alt="Plug Icon"
            className="plug-icon dark-icon"
            data-id="plug icon"
          />
          <span data-id="unplug-text" className="text-capitalize">
            {Unplug}
          </span>
        </button>
      </div>
    );
  }
}
export default withNamespaces('translations')(UnPlugButton as any);
export { UnPlugButton as UnPlugButton }; //(only for testing purpose).

import { unavailable } from '@common/assets/images';
import React from 'react';

interface StationNotCompatibleProps {
  t: (arg0: string) => string;
}

const StationNotCompatible = ({ t }: StationNotCompatibleProps) => {
  return (
    <div data-id="station-not-compatible">
      <img
        src={unavailable}
        className="unavailable-network-image"
        alt="Station not compatible Icon"
      />
      <span className="empty-image text-red">{t('station_not_compatible')}</span>
    </div>
  );
};

export default StationNotCompatible;

import React, { Component } from 'react';
import Modal from 'react-modal';
import { withNamespaces } from 'react-i18next';
import {
  connect_instruction,
  select_instruction,
  unplug_instruction,
  activate_QR_code,
  connect_instruction_retail_dark,
  select_instruction_retail_dark,
  unplug_instruction_retail_dark,
  qr_code_login_page,
} from '@common/assets/images';
import { AMPLITUDE_CONSTANTS, COUNTRY_NUMBERS } from '@common/enums';
import versionData from './../../../versionData.json';
import AmplitudeService from '@common/services/amplitude.service';
import { getCountryCode, LocalStorageUtils } from '@common/utilities';

export interface RetailHelpModalProps {
  t: (arg0: string, arg1?: any) => string;
  isOpen: boolean;
  vehicleIsCharging: boolean | null;
  closeModal: (reload: boolean) => void;
}
export interface RetailHeaderModalState {
  countryNumber: string;
  reload: boolean;
}

class RetailHelpModal extends Component<RetailHelpModalProps, RetailHeaderModalState> {
  readonly amplitudeService: AmplitudeService;
  readonly activeSubscription: boolean | null;
  constructor(props: RetailHelpModalProps) {
    super(props);
    this.state = {
      countryNumber: COUNTRY_NUMBERS.US,
      reload: false,
    };
    this.activeSubscription = LocalStorageUtils.getActiveSubscription();
    this.amplitudeService = new AmplitudeService();
  }

  public async componentDidMount(): Promise<any> {
    this.setState({ countryNumber: getCountryCode() });
  }
  private handleClearButton = (): void => {
    const amplitudeObj = {
      eventType: AMPLITUDE_CONSTANTS.CLEAR_BUTTON_TAPPED,
      screenName: AMPLITUDE_CONSTANTS.HELP_ICON_MODAL,
    };
    this.amplitudeService.postAmplitudeData(amplitudeObj);
    this.setState({ reload: !this.state.reload });
  };

  public render(): React.ReactNode {
    const { t, isOpen, closeModal, vehicleIsCharging } = this.props;
    const { countryNumber, reload } = this.state;
    const fleet_customers_text = { __html: t('fleet_customers') };
    return (
      <Modal isOpen={isOpen} className="help-modal-retail container">
        <div className="help-modal-header-retail col-12">
          <span
            data-id="help-modal-title-retail"
            className="help-modal-title help-modal-sub-title-1 text-base--40 my-3">
            {t('charging_blue_oval_charging_network')}
          </span>
        </div>
        <div className="help-modal-body-retail container">
          <div className="row mt-1 col-12">
            <div data-id="help-modal-sub-title-2" className="help-modal-sub-title-2">
              {t('seamless_activation_payments')}
            </div>
          </div>

          <div className="row instruction-block col-12 p-3 mb-4" data-id="instruction-block">
            <div className="instruction-segment">
              <img
                data-id="select-image"
                className="instruction-image light-icon col-4"
                src={select_instruction}
              />
              <img
                data-id="select-image-dark"
                className="instruction-image dark-icon col-4"
                src={select_instruction_retail_dark}
              />
              <div className="instruction-text col-8" data-id="select-text">
                <span className="instruction-text-select">{t('instruction_text_select')}</span>
              </div>
            </div>
            <div className="instruction-segment">
              <img
                data-id="connect-image"
                className="instruction-image light-icon col-4"
                src={connect_instruction}
              />
              <img
                data-id="connect-image-dark"
                className="instruction-image dark-icon col-4"
                src={connect_instruction_retail_dark}
              />
              <div className="instruction-text col-8" data-id="activate-text">
                <span className="instruction-text-activate">{t('instruction_text_activate')}</span>
              </div>
            </div>
            <div className="instruction-segment">
              <img
                data-id="unplug-image"
                className="instruction-image light-icon col-4"
                src={unplug_instruction}
              />
              <img
                data-id="unplug-image-dark"
                className="instruction-image dark-icon col-4"
                src={unplug_instruction_retail_dark}
              />
              <div className="instruction-text col-8" data-id="unplug-text">
                <span className="instruction-text-unplug">{t('instruction_text_unplug')}</span>
              </div>
            </div>
          </div>

          <div className="row col-12 mb-5">
            <div className="col instruction-activate p-3">
              <div className="row p-3">
                <div className="col-5 m-auto">
                  <img
                    data-id="activate-qr-image"
                    className="activate-qr-image"
                    src={!!this.activeSubscription ? qr_code_login_page : activate_QR_code}
                  />
                </div>
                <div className="col-7 m-auto">
                  <div className="activate-qr-text" data-id="activate-qr-text">
                    <span>
                      {!!this.activeSubscription ? t('manage_qr_text') : t('activate_qr_text')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col instruction-fleet p-3">
              <div className="fleet-customers-text p-3" data-id="fleet_customers">
                <span className="" dangerouslySetInnerHTML={fleet_customers_text}></span>
                <div />
              </div>
            </div>
          </div>
          {!!this.activeSubscription && (
            <div className="row col-12 mb-4">
              <div className="col-12">
                <span className="help-text" data-id="disable-desc">
                  {t('r_disable_desc')}
                </span>
              </div>
            </div>
          )}
          <div className="col-12 mb-4">
            <div className="col-12">
              <span className="help-text" data-id="support-number">
                {t('support_number', { countryNumber })}
              </span>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-between">
            <div>
              <span className="help-text" data-id="version-number">
                {t('version') + versionData.buildRevision}
              </span>
            </div>
            <div>
              {!this.props.vehicleIsCharging && (
                <a
                  className={`float-end help-text ${reload ? 'clear-button-activate' : ''}`}
                  data-id="clear-button"
                  onClick={this.handleClearButton}>
                  {t('Clear')}
                </a>
              )}
            </div>
          </div>
        </div>
        {/* starting of the button section */}
        <div className="row center-button col-12">
          <div className="mt-4">
            <button
              data-id="close-btn-help-modal"
              onClick={() => closeModal(reload)}
              className="close-btn-help-modal">
              {t('close_button')}
            </button>
          </div>
        </div>
        {/* end of the button section */}
      </Modal>
    );
  }
}

export default withNamespaces('translations')(RetailHelpModal as any);
export { RetailHelpModal as RetailHelpModal }; //(only for testing purpose).

export enum FILTER_TYPE_CONTENT_KEY {
  REGULAR_CHARGER = 'regular_charger',
  FAST_CHARGER = 'dc_fast_chargers',
  ULTRA_FAST_CHARGER = 'ultra_fast',
  AVAILABLE_CHARGER = 'confirm_available_chargers',
  FORD_NETWORK = 'ford_network',
  PLUG_N_CHARGE = 'plug_n_charge',
  CLEARANCE = 'clearance',
  TRAILER_ACCESS = 'trailer_access',
  ATTACHED_TRAILER = 'attached_trailer',
  DETACHED_TRAILER = 'detached_trailer',
  FREE_CHARGE = 'free_charge',
  MIN_RATING = 'min_rating',
  RESTRICTED_CHARGERS = 'include_restricted_chargers',
  NETWORK_ID = 'networkId',
  HIDE_STATIONS_ADAPTER_REQUIRED = 'hide_stations_adapter_required',
}

export enum CHARGER_TYPES {
  dc_fast_chargers = 'Fast',
  ultra_fast = 'Ultra Fast',
  ford_network = 'In-Network',
}

export const CLEARANCE_DEFAULTS = {
  METER_MIN: 2.0,
  METER_MAX: 5.0,
  FEET_MIN: 6.5,
  FEET_MAX: 16.5,
};

import { AmplitudeServicePayload } from '@common/interfaces/amplitudeData.interface';

export const createAmplitudeObject = (amplitudeServicePayload: AmplitudeServicePayload): any => {
  const payload = {
    events: [
      {
        device_id: amplitudeServicePayload.vinNumber.replace(/"/gi, ''),
        user_id: amplitudeServicePayload.vinNumber.replace(/"/gi, ''),
        event_type: amplitudeServicePayload.eventType,
        device_type: amplitudeServicePayload.deviceType,
        platform: amplitudeServicePayload.platform,
        country: amplitudeServicePayload.country,
        region: amplitudeServicePayload.region,
        city: amplitudeServicePayload.city,
        language: amplitudeServicePayload.language,
        event_properties: {
          locationId: amplitudeServicePayload.locationId,
          evseId: amplitudeServicePayload.evseId,
          chargerNetworkName: amplitudeServicePayload.chargerNetworkName,
          evsePowerLevel: amplitudeServicePayload.evsePowerLevel,
          isChargerPnCCapable: amplitudeServicePayload.isChargerPnCCapable,
          isPluggedIn: amplitudeServicePayload.isPluggedIn,
          screenName: amplitudeServicePayload.screenName,
          callBackId: amplitudeServicePayload.callBackId,
          status: amplitudeServicePayload.status,
          traceID: amplitudeServicePayload.traceID,
          duration: amplitudeServicePayload.duration,
          Timestamp: new Date(),
          chargeType: amplitudeServicePayload.chargeType,
          isInNetwork: amplitudeServicePayload.isInNetwork,
          isAvailable: amplitudeServicePayload.isAvailable,
          isFree: amplitudeServicePayload.isFree,
          isNetworkName: amplitudeServicePayload.isNetworkName,
          isPlugNCharge: amplitudeServicePayload.isPlugNCharge,
          isRestrictedAccess: amplitudeServicePayload.isRestrictedAccess,
          minimumRating: amplitudeServicePayload.minimumRating,
          startRange: amplitudeServicePayload.startRange,
          endRange: amplitudeServicePayload.endRange,
          distanceAdded: amplitudeServicePayload.distanceAdded,
          startSOC: amplitudeServicePayload.startSOC,
          endSOC: amplitudeServicePayload.endSOC,
          timeOnCharger: amplitudeServicePayload.timeOnCharger,
          statusCode: amplitudeServicePayload.statusCode,
          clearance: amplitudeServicePayload.clearance,
          trailerAccess: amplitudeServicePayload.trailerAccess,
          attachedTrailer: amplitudeServicePayload.attachedTrailer,
          detachedTrailer: amplitudeServicePayload.detachedTrailer,
        },
      },
    ],
  };
  return payload;
};

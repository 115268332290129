/**
 * Convert string to boolean
 *
 * To get boolean `true`, string must contain "true" despite its case
 * Function will return `false` for any other values including `undefined`
 * If boolean is passed, function will return the value as is
 *
 */
export function toBoolean(input: string | boolean | undefined): boolean {
  if (typeof input === 'boolean') return input;

  return !!input && input.toLowerCase() === 'true';
}

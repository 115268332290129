export function isPriceMayVary(price: string): boolean {
  return price.length > 15;
}

export function getMinifiedPriceText(price: string, priceMayVaryString: string): string {
  const splitPrice = price.split(' ');
  let splitPosition = 3;
  const hasEurString = splitPrice.findIndex((i) => i.toLowerCase() === 'eur');
  if (hasEurString !== -1 && hasEurString < 3) {
    splitPosition = 4;
  }
  return splitPrice.slice(0, splitPosition).join(' ') + priceMayVaryString;
}

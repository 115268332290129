import { SyncChargingStatusConfigs } from '@common/data/syncChargingStatus';

const syncChargingStatusExists = (status: string | null): boolean => {
  if (!!status && !!SyncChargingStatusConfigs[status]) return true;
  return false;
};

export const getSyncChargingStatusText = (status: string | null): string => {
  if (!!status && syncChargingStatusExists(status)) {
    return SyncChargingStatusConfigs[status].statusText;
  }
  return SyncChargingStatusConfigs['default'].statusText;
};

export const getSyncChargingStatus = (status: string | null): boolean => {
  if (!!status && syncChargingStatusExists(status)) {
    return SyncChargingStatusConfigs[status].charging;
  }
  return SyncChargingStatusConfigs['default'].charging;
};

export const getFastChargingStatus = (status: string | null): boolean => {
  if (!!status && syncChargingStatusExists(status)) {
    return SyncChargingStatusConfigs[status].isFastCharging;
  }
  return SyncChargingStatusConfigs['default'].isFastCharging;
};

export const getChargeTargetReachedStatus = (status: string | null): boolean => {
  if (!!status && syncChargingStatusExists(status)) {
    return SyncChargingStatusConfigs[status].isTargetReached;
  }
  return SyncChargingStatusConfigs['default'].isTargetReached;
};

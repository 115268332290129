import React, { Component } from 'react';
import Modal from 'react-modal';
import { withNamespaces } from 'react-i18next';

export interface RetailTimeOutModalProps {
  t: (arg0: string) => string;
  isOpen: boolean;
  closeModal: () => void;
}

class RetailTimeOutModal extends Component<RetailTimeOutModalProps> {
  render(): React.ReactNode {
    const { isOpen, t, closeModal } = this.props;
    const modalTitle = 'modal_timeout_title_retail';
    const modalDesc = 'timeout_text_retail';
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="timeout-modal"
        closeTimeoutMS={500}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}>
        <div className="modal__header">
          <h2 data-id="title" className="modal__header-title">
            {t(modalTitle)}
          </h2>
        </div>

        <div className="modal__body">
          <div className="modal__body-content">
            <p data-id="descriptionTimeout">{t(modalDesc)}</p>
          </div>

          <div className="modal__body-btn">
            <button data-id="close-btn-timeout" onClick={closeModal} className="button--secondary">
              {t('ok')}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
export default withNamespaces('translations')(RetailTimeOutModal as any);
export { RetailTimeOutModal as RetailTimeOutModal };

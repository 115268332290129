import LocalStorageUtils from './localStorage';
const getFlagValue = (flag: string): boolean => {
  const flags = LocalStorageUtils.getFeatureFlags();
  if (flag && flag !== undefined && flags && flags !== undefined && flags[flag] !== undefined) {
    const jsonFlag = flags[flag];
    return flags && jsonFlag['key'] === 'on';
  } else {
    return false;
  }
};
export default getFlagValue;

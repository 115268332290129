export enum COUNTRY_CODE {
  EN_GB = 'en_GB',
  ES_US = 'es_US',
  IT_IT = 'it_IT',
  DE_DE = 'de_DE',
  FR_FR = 'fr_FR',
  NL_BE = 'nl_BE',
  NL_NL = 'nl_NL',
  EN_US = 'en_US',
  FR_CA = 'fr_CA',
  CS_CZ = 'cs_CZ',
  DA_DK = 'da_DK',
  EL_GR = 'el_GR',
  ES_ES = 'es_ES',
  ES_MX = 'es_MX',
  FI_FI = 'fi_FI',
  HU_HU = 'hu_HU',
  NO_No = 'no_NO',
  PL_PL = 'pl_PL',
  PT_PT = 'pt_PT',
  ro_RO = 'ro_RO',
  SL_SI = 'sl_SI',
  SL_SL = 'sl_SL',
  SV_SE = 'sv_SE',
}

export enum LOCAL_STORAGE_NAME {
  CHARGERS_NEARBY = 'chargersNearByObj',
  ACTIVE_CHARGER_INFO = 'activeChargerInfo',
  CONNECTOR_INFO = 'connectorInfo',
  ACTIVATED_CONNECTOR_INFO = 'activatedConnectorInfo',
  FILTER_OPTION = 'filterOptionsObj',
  CALLBACK_ID = 'callbackId',
  CHARGER_PERCENTAGE = 'chargerPercentage',
  CHARGER_RANGE = 'chargerRange',
  SESSION_ID = 'sessionId',
  VIN_NUMBER = '_ref',
  LANGUAGE_CODE = 'languageCode',
  CHARGE_START_TIME = 'chargeStartTime',
  DISTANCE_ADDED = 'distanceAdded',
  LOCATION_DATA = 'locationData',
  DISTANCE_UNIT = 'preferredDistanceUnitValue',
  SAVED_LOCATION_DATA = 'savedLocationData',
  CURRENTLY_CHARGING_LOCATIONID = 'currentlyChargingLocationID',
  DEVICE_TYPE = 'deviceType',
  FEATURE_FLAG = 'flags',
  SAVED_SEARCHES = 'savedSearches',
  CURRENT_PAGE = 'currentPage',
  ACTIVE_SUBSCRIPTION = 'activeSubscription',
  AUTHORIZATION_TYPE = 'authorizationType',
  SUBSCRIPTION_TOGGLE_VALUE = 'subscriptionToggleValue',
  CONNECTOR_IDENTIFIER_CONFIGS = 'connectorIdentifierConfigs',
  ACTIVATING_STATUS_CONFIGS = 'activatingStatusConfigs',
  SHOW_BOCN_MODAL = 'showBOCNModal',
  SHOW_BOCN_MODAL_CHARGING_LOCATIONS = 'showBOCNModalChargingLocations',
  SEARCHED_VALUE = 'searchedValue',
  AUTOSUGGEST_LAT_LONG = 'autosuggestLatAndLong',
  SET_LANDING_PAGE_VIEW = 'setLandingPageView',
  TIME_OUT_PLUGIN = 'timeoutplugin',
  TIME_OUT_ACTIVATE = 'timeoutactivate',
}

import React, { Component } from 'react';
import Modal from 'react-modal';
import { withNamespaces } from 'react-i18next';
import { straight_sculpt } from '@common/assets/images';
import { payments_qr_code } from '@common/assets/images';

export interface UnableToActiveErrorModalProps {
  t: (arg0: string, arg1?: any) => string;
  isOpen: boolean;
  closeModal: () => void;
}
export interface UnableToActiveErrorModalState {}

class UnableToActiveErrorModal extends Component<
  UnableToActiveErrorModalProps,
  UnableToActiveErrorModalState
> {
  constructor(props: UnableToActiveErrorModalProps) {
    super(props);
  }

  public render(): React.ReactNode {
    const { t, isOpen, closeModal } = this.props;
    return (
      <Modal isOpen={isOpen} className="unable-to-active-error-modal">
        <div className="unable-to-active-error-modal-header">
          <span
            data-id="unable-to-active-error-modal-title"
            className="unable-to-active-error-modal-title">
            {t('modal_timeout_title_retail')}
          </span>
          <img
            src={straight_sculpt}
            className="unable-to-active-error-modal-title-background-image"></img>
        </div>
        <div
          className="unable-to-active-error-modal-body"
          data-id="unable-to-active-error-modal-body">
          <div className="bocn-subscription-info">
            {t('blue_oval_charge_network_subscription_info')}
          </div>
          <div className="payments-icon-button">
            <img
              src={payments_qr_code}
              style={{ display: 'block !important' }}
              alt={t('more_information')}
            />
          </div>
        </div>
        <div
          className="unable-to-active-error-modal-footer"
          data-id="unable-to-active-error-modal-footer">
          <button
            data-id="unable-to-active-error-modal-close-btn"
            className={'unable-to-active-error-modal-close-btn'}
            onClick={() => closeModal()}>
            {t('close_button')}
          </button>
        </div>
      </Modal>
    );
  }
}

export default withNamespaces('translations')(UnableToActiveErrorModal as any);
export { UnableToActiveErrorModal }; //(only for testing purpose).

import React, { ReactElement } from 'react';
import Spinner from '../../src/components/spinner/Spinner';
import ThreeDotSpinner from '../../src/components/threeDotSpinner/threeDotSpinner';
import { VEHICLE_TYPE } from '@common/enums/vehicleType.enum';

export function renderSpinner(vehicleType: VEHICLE_TYPE): ReactElement {
  if (vehicleType === VEHICLE_TYPE.MENLO) {
    return <ThreeDotSpinner />;
  } else {
    return <Spinner />;
  }
}

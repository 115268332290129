// Sort an array of numbers in ascending order
export function sortArrayAsc(arr: number[]): number[] {
  return arr.sort((a: number, b: number) => a - b);
}

// Return first item in an array
export function getFirstArrayItem(arr: any[]): any {
  return arr[0];
}

// Return last item in an array
export function getLastArrayItem(arr: any[]): any {
  return arr[arr.length - 1];
}

export const getMaxConnectorSpeed = (chargers: any): string => {
  if (chargers && chargers.length) {
    const maxConnectorSpdCollection: number[] = [];
    chargers.forEach((charger: any) =>
      maxConnectorSpdCollection.push((charger['connectorSpd'] || charger['maxConnectorSpd']) ?? 0),
    );
    const maxiConnectedSpd = maxConnectorSpdCollection.length
      ? Math.max(...maxConnectorSpdCollection)
      : 0;
    return maxiConnectedSpd <= 49
      ? 'Regular'
      : maxiConnectedSpd >= 50 && maxiConnectedSpd <= 99
      ? 'Fast'
      : 'Ultra Fast';
  }
  return 'Regular';
};

import { AMPLITUDE_CONSTANTS } from '@common/enums';
export default class ParkingObjectFilter {
  static ATTACHED_TRAILER = new ParkingObjectFilter(
    AMPLITUDE_CONSTANTS.ATTACHED_TRAILER,
    AMPLITUDE_CONSTANTS.TRAILER_FRIENDLY,
  );
  static DETATCHED_TRAILER = new ParkingObjectFilter(
    AMPLITUDE_CONSTANTS.DETACHED_TRAILER,
    AMPLITUDE_CONSTANTS.TRAILER_PARKING,
  );
  static PARKING_OBJ_ARRAY = [this.ATTACHED_TRAILER, this.DETATCHED_TRAILER];

  constructor(public amplitudeDescription, public payloadDescription) {
    this.amplitudeDescription = amplitudeDescription;
    this.payloadDescription = payloadDescription;
  }

  static filterTextsForAmplitude(trailerFilters: string[]): string[] {
    const convertedFilters: string[] = [];
    trailerFilters.forEach((filter: string) => {
      const selectedFilter: string = this.PARKING_OBJ_ARRAY.find(
        (parkingObj) =>
          parkingObj.payloadDescription === filter || parkingObj.amplitudeDescription === filter,
      )?.amplitudeDescription;
      if (selectedFilter) {
        convertedFilters.push(selectedFilter);
      }
    });
    return convertedFilters;
  }
}

import React, { Component } from 'react';
import Modal from 'react-modal';
import { withNamespaces } from 'react-i18next';
import './offlineModal.scss';

export interface OfflineModalProps {
  t: (arg0: string) => string;
  isOpen: boolean;
  closeModal: () => void;
  isVehicleConnectivityAllowed: boolean;
}

class OfflineModal extends Component<OfflineModalProps> {
  render(): React.ReactNode {
    const { isOpen, t, closeModal, isVehicleConnectivityAllowed } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="offline-modal"
        closeTimeoutMS={500}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}>
        <div className="modal__header">
          <h2 data-id="title" className="modal__header-title">
            {!isVehicleConnectivityAllowed ? t('connectivity_error') : t('connection_lost')}
          </h2>
        </div>

        <div className="modal__body">
          <div className="modal__body-content">
            <p data-id="description">
              {!isVehicleConnectivityAllowed
                ? t('connectivity_error_desc')
                : t('connection_lost_desc')}
            </p>
          </div>

          <div className="modal__body-btn">
            <button
              data-id="close-btn"
              onClick={() => {
                if (!isVehicleConnectivityAllowed && window && window.fordSync) {
                  window.fordSync.exitApp();
                } else {
                  closeModal();
                }
              }}
              className="button--secondary">
              {t('ok')}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
export default withNamespaces('translations')(OfflineModal as any);
export { OfflineModal };

import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';

export interface ThreeDotSpinnerProps {
  t: (arg0: string) => string;
  history: any;
}

class ThreeDotSpinner extends Component<ThreeDotSpinnerProps> {
  constructor(props: ThreeDotSpinnerProps) {
    super(props);
  }
  render(): React.ReactNode {
    return (
      <div className="three-dot-spinner-container">
        <div id="test-spinner" className="spinner-container">
          <div className="loadingContainer">
            <div className="loading"></div>
            <div className="loading"></div>
            <div className="loading"></div>
          </div>
        </div>
      </div>
    );
  }
}
export default withNamespaces('translations')(ThreeDotSpinner as any);
export { ThreeDotSpinner }; //(only for testing purpose).

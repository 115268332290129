import { getFirstArrayItem, getLastArrayItem, sortArrayAsc } from './arrays';
import { ChargerData, ConnectorData } from '@common/interfaces';

export function getConnectorSpeedVal(chargers: ChargerData[] | null | undefined): string {
  const speedArray: number[] = [];

  if (!!chargers) {
    chargers.forEach((charger) => {
      const connectors: ConnectorData[] | null = charger.connectors;

      if (!!connectors) {
        connectors.forEach((connector) => {
          if (!!connector.connectorSpd) {
            speedArray.push(connector.connectorSpd);
          }
        });
      }
    });
  }

  if (speedArray.length > 0) {
    return getSpeedRange(speedArray);
  } else {
    return '';
  }
}

export function getSpeedRange(speedArray: number[]): string {
  const sortedArray = sortArrayAsc(speedArray);
  const slowest: number = getFirstArrayItem(sortedArray);
  const fastest: number = getLastArrayItem(sortedArray);

  if (slowest === fastest) {
    return `${fastest}`;
  } else {
    return `${slowest}-${fastest}`;
  }
}

export const SyncChargingStatusConfigs: any = {
  '0': {
    statusText: 'not ready',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
  '1': {
    statusText: 'fault inside car',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
  '2': {
    statusText: 'fault outside car',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
  '3': {
    statusText: 'EVSE not compatible',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
  '4': {
    statusText: 'EVSE not detected',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
  '5': {
    statusText: 'EVSE paused',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
  '6': {
    statusText: 'ENUM_ChargeStatus_ChargeScheduled',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
  '7': {
    statusText: 'Charging AC',
    charging: true,
    isFastCharging: false,
    isTargetReached: false,
  },
  '8': {
    statusText: 'Fast Charging DC',
    charging: true,
    isFastCharging: true,
    isTargetReached: false,
  },
  '9': {
    statusText: 'Charging Inductive',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
  '10': {
    statusText: 'Charging System Maintain',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
  '11': {
    statusText: 'Cabin preconditioning',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
  '12': {
    statusText: 'Drive conditioning',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
  '13': {
    statusText: 'Charge target reached',
    charging: false,
    isFastCharging: false,
    isTargetReached: true,
  },
  '14': {
    statusText: 'Charge Start Commanded',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
  '15': {
    statusText: 'Charge Stop Commanded',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
  default: {
    statusText: 'unknown',
    charging: false,
    isFastCharging: false,
    isTargetReached: false,
  },
};

import { adapter_needed, adapter_needed_dark } from '@common/assets/images';
import React from 'react';

interface AdapterNeededProps {
  t: (arg0: string) => string;
}

const AdapterNeeded = ({ t }: AdapterNeededProps) => {
  return (
    <div data-id="adapter-tags" className="adapter-needed-info text-base--34">
      <img
        src={adapter_needed}
        className="adapter-needed-image light-icon"
        alt="Adapter needed light icon"
      />
      <img
        src={adapter_needed_dark}
        className="adapter-needed-image dark-icon"
        alt="Adapter needed dark icon"
      />
      {t('adapter_needed')}
    </div>
  );
};

export default AdapterNeeded;

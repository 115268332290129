import { ActivatedObjData } from '@common/interfaces';

const errorCodes = [1003, 1004, 1005];
const eVSSErrorCodes = [2002];
const fleetErrorCodes = [1003, 1004, 1005, 1017];

export const extractErrorCodes = (message: string): string => {
  const NumberRegex = /(\d{4})/gm;
  const currentErrorCodes = message.match(NumberRegex);
  let hasCustomError = '';
  if (currentErrorCodes && currentErrorCodes.length > 0) {
    const commonArrays = currentErrorCodes.filter((n) => {
      return errorCodes.indexOf(parseInt(n)) !== -1;
    });
    hasCustomError = commonArrays.length > 0 ? commonArrays[0] : '';
  }
  return hasCustomError;
};

export const checkPublicChargingUnavailble = (message: string): string => {
  const NumberRegex = /(\d{4})/gm;
  const currentErrorCodes = message.match(NumberRegex);
  let hasCustomError = false;
  if (currentErrorCodes && currentErrorCodes.length > 0) {
    const commonArrays = currentErrorCodes.filter((n) => {
      return fleetErrorCodes.indexOf(parseInt(n)) !== -1;
    });
    hasCustomError = commonArrays.length > 0;
  }
  return hasCustomError ? 'custom' : 'default';
};
export const webSocketErrorHandler = (data: any): string => {
  let currentErrorCode = '';
  const errorRes = JSON.stringify(data?.response?.data);
  const errorResJSON = JSON.parse(errorRes);
  const statusDescValue = 'messages' in errorResJSON.error ? errorResJSON.error.messages['0'] : '';
  const code = extractErrorCodes(statusDescValue.toString());
  if (code) {
    currentErrorCode = errorCodes.indexOf(Number(code)) !== -1 ? code : '';
  } else {
    currentErrorCode = checkPublicChargingUnavailble(statusDescValue);
  }
  return currentErrorCode;
};

export const getTimeoutStatus = (status: any): boolean => {
  return status === '408' || status === 'TIMEOUT';
};

export const getErrorCodes = (error: any): boolean => {
  return error && errorCodes.indexOf(Number(error)) !== -1;
};

export const getEVSSErrorCode = (error: any): boolean => {
  return error && eVSSErrorCodes.indexOf(Number(error)) !== -1;
};

export const getEvsePowerLevel = (connectorObj: ActivatedObjData): string => {
  return connectorObj.evsePowerLevel ? connectorObj.evsePowerLevel : '';
};

export enum AMPLITUDE_CONSTANTS {
  ACTIVATE_BUTTON_TAPPED = 'Activate Button Tapped',
  STOP_CHARGING_BUTTON_TAPPED = 'Stop Charging Button Tapped',
  STOP_CHARGING_MODAL = 'Stop Charging Modal',
  CHARGE_ASSIST = 'Charge Assist',
  CONFIRM_DETAILS = 'Confirm Details',
  GET_CHARGERS_INVOKED = 'Get Charger Locations Invoked',
  SEARCH_TAPPED = 'Search Button Tapped',
  NEARBY_CHARGERS = 'Nearby Chargers',
  ACTIVATE_BUTTON_RESPONSE = 'Activate Button Response',
  STOP_VEHICLE_BUTTON_RESPONSE = 'Stop Vehicle Button Response',
  CHARGING_SUMMARY = 'Charging Summary',
  EVSS_CALLBACK_RECEIVED = 'EVSS Callback Received',
  REQUEST_TIMEOUT = 'Request Timeout',
  FILTER_MODAL = 'Filter Modal',
  FILTERS_APPLIED = 'Filters Applied',
  HELP_ICON_TAPPED = 'Help Icon Tapped',
  HEADER = 'Header',
  CHARGER_LOCATIONS_DATA_RECEIVED = 'Charger locations data received',
  CHARGER_LOCATIONS_REQUEST_FAILURE = 'Exception: Charger locations request failure',
  CHARGER_LOCATIONS_EMPTY_DATA_RECEIVED = 'Empty locations data received',
  SUCCESSFUL_ACTIVATION = 'Successful Activation',
  CHARGING_IN_PROGRESS = 'Charging In Progress',
  REFRESH_TAPPED = 'Refresh Button Tapped',
  BACK_TAPPED = 'Back Button Tapped',
  CHARGING_SESSION_BEGIN = 'Charging Session Begin',
  CHARGE_SESSION_COMPLETE = 'Charging Session Complete',
  CLEAR_BUTTON_TAPPED = 'Clear Button Tapped',
  HELP_ICON_MODAL = 'Help Icon Modal',
  FILTER_CLEAR_TAPPED = 'Filter Clear Tapped',
  FILTER_GROUP = 'Filter Group',
  CLEARANCE = 'Clearance',
  FILTER_BUTTON_TAPPED = 'Filter Button Tapped',
  ATTACHED_TRAILER = 'attachedTrailer',
  DETACHED_TRAILER = 'detachedTrailer',
  TRAILER_ACCESS = 'trailerAccess',
  TRAILER_FRIENDLY = 'Trailer Friendly',
  TRAILER_PARKING = 'Trailer Parking',
  NAVIGATION_BUTTON_TAPPED = 'Navigation Button Tapped',
  CHARGING_LOCATIONS = 'Charging Locations',
  CVPOD_SUBSCRIPTION_VALUE = 'CvPod Subscription Value',
  CVPOD_SUBSCRIPTION_REQUEST_FAILURE = 'Exception: CvPod API request failure',
  DISCLAIMER_BUTTON_TAPPED = 'Disclaimer Button Tapped',
  IAM_HERE_NOW_BUTTON_TAPPED = "I'm Here Now button Tapped",
  CHARGING_SPEED_BUTTON_TAPPED = 'Charging Speed Button Tapped',
  VIEW_MAP_BUTTON_TAPPED = 'View (Map) Button Tapped',
  VIEW_LIST_BUTTON_TAPPED = 'View (List) Button Tapped',
}
export const vehicleProgramInformation = {
  0: 'unknown',
  1: 'C344',
  2: 'C346_C519',
  3: 'CD391',
  4: 'CD533',
  5: 'U611',
  6: 'U625',
  7: 'CX482',
  8: 'CX483',
  9: 'CX727',
  10: 'P758',
  11: 'P702',
  12: 'CD539',
  13: 'U725',
  14: 'U540',
  15: 'CX482N',
  16: 'P558',
  17: 'P702_ICA_Raptor',
  18: 'U553',
  19: 'U554',
  20: 'CD542',
  21: 'V363',
  22: 'P702_BEV',
  23: 'U704',
  24: 'P703',
  25: 'V713',
  26: 'CX727GT',
};

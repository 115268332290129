import {
  GeoLocationCoords,
  GeoLocationObject,
  GeoLocationPositionOptions,
} from '@common/interfaces';
import { LocalStorageUtils } from '@common/utilities';

export default class GeoLocationService {
  // Request Position returns a promise of current GeoLaction position
  public requestPosition(): Promise<any> {
    const options: GeoLocationPositionOptions = {
      enableHighAccuracy: true,
      timeout: 10000, // time in millis when error callback will be invoked
      maximumAge: 0, // max cached age of gps data, also in millis
    };

    return new Promise((resolve) => {
      navigator.geolocation.watchPosition(
        (pos: GeoLocationCoords) => {
          this.saveLocationObj(pos);
          resolve(pos);
        },
        (err: any) => {
          console.error(err);
          resolve(null);
        },
        options,
      );
    });
  }
  public saveLocationObj(position: any): void {
    const locationObj: GeoLocationObject = {
      accuracy: position.coords.accuracy,
      altitude: null,
      altitudeAccuracy: null,
      heading: null,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      speed: null,
    };

    if (locationObj.latitude && locationObj.longitude) {
      LocalStorageUtils.setLocationData(locationObj);
    }
  }
}

export enum API_CACHE_TYPE {
  NO_CACHE = 'no-cache',
}

export enum API_CONTENT_TYPE {
  JSON = 'application/json',
}

export enum API_CREDENTIALS {
  SAME_ORIGIN = 'same-origin',
  INCLUDE = 'include',
  OMIT = 'omit',
}

export enum API_METHODS {
  GET = 'GET',
  POST = 'POST',
}

export enum API_MODE {
  NO_CORS = 'no-cors',
  CORS = 'cors',
  SAME_ORIGIN = 'same-origin',
}

import { LOCAL_STORAGE_NAME } from '@common/enums';
import {
  AutoSuggestionSearchObject,
  FilterOption,
  StationLocationObject,
  SuggestionValueObject,
} from '@common/interfaces';

export default class LocalStorageUtils {
  /**
   * Sets a local storage item
   * @param name
   * @param value
   */
  static setLocalStorageItem(name: string, value: string): void {
    localStorage.setItem(name, value);
  }

  /**
   * Gets a local storage item
   * @param name
   */
  static getLocalStorageItem(name: string): string | null {
    return localStorage.getItem(name);
  }

  /**
   * Clear a local storage item
   * @param name
   */
  static clearLocalStorageItem(name: string): void {
    localStorage.removeItem(name);
  }

  /**
   * Sets a session storage item
   * @param name
   * @param value
   */
  static setSessionStorageItem(name: string, value: string): void {
    sessionStorage.setItem(name, value);
  }

  /**
   * Gets a session storage item
   * @param name
   */
  static getSessionStorageItem(name: string): string | null {
    return sessionStorage.getItem(name);
  }

  /**
   * Set and Get to local storage
   * a list of nearby chargers
   */
  static setChargersNearby(value: StationLocationObject[]): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.CHARGERS_NEARBY, JSON.stringify(value));
  }

  static getChargersNearby(): StationLocationObject[] {
    const value = this.getLocalStorageItem(LOCAL_STORAGE_NAME.CHARGERS_NEARBY);
    if (!value) {
      return [];
    }
    return JSON.parse(value);
  }

  /**
   * Set and Get & clear to local storage
   * information for one active charge location, user selected
   */
  static setActiveChargerInfo(value: string | any): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.ACTIVE_CHARGER_INFO, JSON.stringify(value));
  }
  static getActiveChargerInfo(): string | any {
    return this.getLocalStorageItem(LOCAL_STORAGE_NAME.ACTIVE_CHARGER_INFO);
  }
  static clearActiveChargerInfo(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.ACTIVE_CHARGER_INFO);
  }

  /**
   * Set and Get to local storage
   * information for one connector, user selected
   */
  static setConnectorInfo(value: string | any): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.CONNECTOR_INFO, JSON.stringify(value));
  }
  static getConnectorInfo(): string | any {
    return this.getLocalStorageItem(LOCAL_STORAGE_NAME.CONNECTOR_INFO);
  }

  /**
   * Set and Get & clear to local storage
   * information for one activated connector, user selected
   */
  static setActivatedConnectorInfo(value: string | any): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.ACTIVATED_CONNECTOR_INFO, JSON.stringify(value));
  }
  static getActivatedConnectorInfo(): string | any {
    return this.getLocalStorageItem(LOCAL_STORAGE_NAME.ACTIVATED_CONNECTOR_INFO);
  }
  static clearActivatedConnectorInfo(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.ACTIVATED_CONNECTOR_INFO);
  }

  /**
   * Set & Get NetworkFilters
   */
  static setFilterOption(filters: FilterOption[]): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.FILTER_OPTION, JSON.stringify(filters));
  }
  static getFilterOption(): FilterOption[] {
    const value = this.getLocalStorageItem(LOCAL_STORAGE_NAME.FILTER_OPTION);
    if (!value) {
      return [];
    }
    return JSON.parse(value);
  }
  /**
   * Set and Get to local storage
   * a callbackId for API start/stop activation.
   */
  static setCallbackId(value: string): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.CALLBACK_ID, value);
  }
  static getCallbackId(): string | null {
    return this.getLocalStorageItem(LOCAL_STORAGE_NAME.CALLBACK_ID);
  }
  static clearCallbackId(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.CALLBACK_ID);
  }

  /**
   * Set and Get to local storage
   * starting vehicle percentage.
   */
  static setStartingVehicleChargePercentage(value: number): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.CHARGER_PERCENTAGE, value.toString());
  }

  static getStartingVehicleChargePercentage(): number {
    const value = this.getLocalStorageItem(LOCAL_STORAGE_NAME.CHARGER_PERCENTAGE);
    return value ? parseInt(value) : 0;
  }

  static clearStartingVehicleChargePercentage(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.CHARGER_PERCENTAGE);
  }

  /**
   * Set and Get to local storage
   * starting vehicle range for added range calculation.
   */
  static setStartingVehicleRange(value: string): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.CHARGER_RANGE, value);
  }

  static getStartingVehicleRange(): string {
    const value = this.getLocalStorageItem(LOCAL_STORAGE_NAME.CHARGER_RANGE);
    if (!value) {
      return '---';
    }
    return value;
  }

  static clearStartingVehicleRange(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.CHARGER_RANGE);
  }
  /**
   * Set and Get to local storage
   * starTime for charging session
   */
  static setChargeStartTime(value: number): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.CHARGE_START_TIME, JSON.stringify(value));
  }
  static getChargeStartTime(): number {
    const value = this.getLocalStorageItem(LOCAL_STORAGE_NAME.CHARGE_START_TIME);
    if (!value) {
      return 0;
    }
    return Number(value);
  }
  static clearChargeStartTime(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.CHARGE_START_TIME);
  }

  /**
   * Set and Get to local storage
   * a sessionId for API start/stop activation.
   */
  static setSessionId(value: string): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.SESSION_ID, value);
  }
  static getSessionId(): string | null {
    return this.getLocalStorageItem(LOCAL_STORAGE_NAME.SESSION_ID);
  }
  static clearSessionId(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.SESSION_ID);
  }

  /**
   * Set and Get & clear to local storage
   * a searched value in the Autosuggest.
   */
  static setSearchedValue(value: string): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.SEARCHED_VALUE, value);
  }
  static getSearchedValue(): string {
    const value = this.getLocalStorageItem(LOCAL_STORAGE_NAME.SEARCHED_VALUE);
    if (!value) {
      return '';
    }
    return value;
  }
  static clearSearchedValue(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.SEARCHED_VALUE);
  }

  /**
   * Set and Get & clear to local storage
   * a lat & long of the searched value.
   */

  static setAutosuggestLatAndLong(autosuggestLatAndLong: AutoSuggestionSearchObject): void {
    this.setLocalStorageItem(
      LOCAL_STORAGE_NAME.AUTOSUGGEST_LAT_LONG,
      JSON.stringify(autosuggestLatAndLong),
    );
  }

  static getAutosuggestLatAndLong(): AutoSuggestionSearchObject {
    const result = this.getLocalStorageItem(LOCAL_STORAGE_NAME.AUTOSUGGEST_LAT_LONG);
    if (result) {
      return JSON.parse(result);
    }
    return {} as AutoSuggestionSearchObject;
  }

  static clearAutosuggestLatAndLong(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.AUTOSUGGEST_LAT_LONG);
  }

  /**
   * Set and Get to local storage
   * a vin for SPLUNK Logging.
   */
  static setVinNumber(value: string): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.VIN_NUMBER, value);
  }
  static getVinNumber(): string {
    const value = this.getLocalStorageItem(LOCAL_STORAGE_NAME.VIN_NUMBER);
    if (!value) {
      return '';
    }
    return value;
  }

  /**
   * Set and Get to local storage
   * a languageCode for API find nearby chargers.
   */
  static setLanguageCode(value: string): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.LANGUAGE_CODE, value);
  }
  static getLanguageCode(): string {
    const value = this.getLocalStorageItem(LOCAL_STORAGE_NAME.LANGUAGE_CODE);
    if (!value) {
      return '';
    }
    return value;
  }

  /**
   * Set and Get to local storage
   * a location data long & lat.....
   */
  static setLocationData(value: string | any): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.SAVED_LOCATION_DATA, JSON.stringify(value));
  }
  static getLocationData(): string | any {
    return this.getLocalStorageItem(LOCAL_STORAGE_NAME.SAVED_LOCATION_DATA);
  }

  /**
   * Set and Get to local storage
   * a current landing page.....
   */
  static setCurrentPage(value: string | any): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.CURRENT_PAGE, JSON.stringify(value));
  }
  static getCurrentPage(): string | any {
    const value = this.getLocalStorageItem(LOCAL_STORAGE_NAME.CURRENT_PAGE);
    if (!value) {
      return '';
    }
    return JSON.parse(value);
  }

  static resetChargingDetails(): void {
    LocalStorageUtils.clearChargeStartTime();
  }

  static resetStartingVehicleRange(): void {
    LocalStorageUtils.clearStartingVehicleRange();
  }

  static resetTimeDetails(): void {
    LocalStorageUtils.clearChargeStartTime();
  }

  static resetActivationDetails(): void {
    LocalStorageUtils.clearSessionId();
    LocalStorageUtils.clearCallbackId();
  }

  /**
   * Set preferredDistanceUnit local storage
   * DistanceUnits will be KM/MI.....
   */
  static setPreferredDistanceUnit(value: string): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.DISTANCE_UNIT, value);
  }
  static getPreferredDistanceUnit(): string | null {
    return this.getLocalStorageItem(LOCAL_STORAGE_NAME.DISTANCE_UNIT);
  }

  /**
   * Set Currently Charging LocationID storage..
   */
  static setCurrentlyChargingLocationID(value: string | null): void {
    if (value !== null) {
      this.setLocalStorageItem(LOCAL_STORAGE_NAME.CURRENTLY_CHARGING_LOCATIONID, value);
    }
  }
  static getCurrentlyChargingLocationID(): string | null {
    return this.getLocalStorageItem(LOCAL_STORAGE_NAME.CURRENTLY_CHARGING_LOCATIONID);
  }

  static clearCurrentlyChargingLocationID(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.CURRENTLY_CHARGING_LOCATIONID);
  }

  /**
   * Set and Get to local storage
   * a device type used in Amplitude logging
   */
  static setDeviceType(value: number): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.DEVICE_TYPE, value.toString());
  }
  static getDeviceType(): number {
    const value = this.getLocalStorageItem(LOCAL_STORAGE_NAME.DEVICE_TYPE);
    if (!value) {
      return 0;
    }
    return Number(value);
  }

  /*Set and Get the feature flags to local storgae */
  static setFeatureFlags(flagObj: string): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.FEATURE_FLAG, flagObj);
  }

  static getFeatureFlags(): any {
    const result = this.getLocalStorageItem(LOCAL_STORAGE_NAME.FEATURE_FLAG);
    if (result) {
      return JSON.parse(result);
    }
    return result;
  }

  static setSavedSearches(suggestions: SuggestionValueObject[]): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.SAVED_SEARCHES, JSON.stringify(suggestions));
  }

  static getSavedSearches(): SuggestionValueObject[] {
    const result = this.getLocalStorageItem(LOCAL_STORAGE_NAME.SAVED_SEARCHES);
    if (result) {
      return JSON.parse(result);
    }
    return [];
  }

  /* Clear entire local storage */
  static clearLocalStorage(): void {
    localStorage.clear();
  }
  static setActiveSubscription(value: boolean): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.ACTIVE_SUBSCRIPTION, JSON.stringify(value));
  }
  static getActiveSubscription(): boolean | null {
    const value = this.getLocalStorageItem(LOCAL_STORAGE_NAME.ACTIVE_SUBSCRIPTION);
    if (!value) return null;
    return JSON.parse(value);
  }

  static setAuthorizationType(value: string): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.AUTHORIZATION_TYPE, JSON.stringify(value));
  }

  static getAuthorizationType(): string | null {
    const value = this.getLocalStorageItem(LOCAL_STORAGE_NAME.AUTHORIZATION_TYPE);
    if (!value) return null;
    return JSON.parse(value);
  }

  static setSubscriptionToggleValue(value: boolean): void {
    this.setSessionStorageItem(LOCAL_STORAGE_NAME.SUBSCRIPTION_TOGGLE_VALUE, JSON.stringify(value));
  }
  static getSubscriptionToggleValue(): boolean | null {
    const value = this.getSessionStorageItem(LOCAL_STORAGE_NAME.SUBSCRIPTION_TOGGLE_VALUE);
    if (!value) return null;
    return JSON.parse(value);
  }

  /**
   * Set and Get & clear to local storage
   * information for one ConnectorIdentifierConfigs.
   */
  static setConnectorIdentifierConfigs(value: string | any): void {
    this.setLocalStorageItem(
      LOCAL_STORAGE_NAME.CONNECTOR_IDENTIFIER_CONFIGS,
      JSON.stringify(value),
    );
  }
  static getConnectorIdentifierConfigs(): string | any {
    return this.getLocalStorageItem(LOCAL_STORAGE_NAME.CONNECTOR_IDENTIFIER_CONFIGS);
  }
  static clearConnectorIdentifierConfigs(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.CONNECTOR_IDENTIFIER_CONFIGS);
  }

  /**
   * Set and Get & clear to local storage
   * information for one ActivatingStatusConfigs.
   */
  static setActivatingStatusConfigs(value: string | any): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.ACTIVATING_STATUS_CONFIGS, JSON.stringify(value));
  }
  static getActivatingStatusConfigs(): string | any {
    return this.getLocalStorageItem(LOCAL_STORAGE_NAME.ACTIVATING_STATUS_CONFIGS);
  }
  static clearActivatingStatusConfigs(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.ACTIVATING_STATUS_CONFIGS);
  }

  /*Set BOCn modal view for the charging locations modal*/
  static setCanSeeBOCNModal(value: boolean): void {
    this.setSessionStorageItem(
      LOCAL_STORAGE_NAME.SHOW_BOCN_MODAL_CHARGING_LOCATIONS,
      JSON.stringify(value),
    );
  }
  static getCanSeeBOCNModal(): boolean | null {
    const value = this.getSessionStorageItem(LOCAL_STORAGE_NAME.SHOW_BOCN_MODAL_CHARGING_LOCATIONS);
    if (!value) return true;
    return JSON.parse(value);
  }

  /* set BOCN modal view value   */

  static setBOCNModalView(value: boolean): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.SHOW_BOCN_MODAL, JSON.stringify(value));
  }
  static getBOCNModalView(): boolean {
    const value = this.getLocalStorageItem(LOCAL_STORAGE_NAME.SHOW_BOCN_MODAL);
    if (!value) return true;
    return JSON.parse(value);
  }

  static setLandingPageView(value: string): void {
    this.setSessionStorageItem(LOCAL_STORAGE_NAME.SET_LANDING_PAGE_VIEW, value);
  }

  static getLandingPageView(): string {
    const value = this.getSessionStorageItem(LOCAL_STORAGE_NAME.SET_LANDING_PAGE_VIEW);
    if (!value) return 'list';
    return value;
  }

  /**
   * Set and Get to local storage
   * a TimeOutPlugIn.
   */
  static setTimeOutPlugIn(value: string): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.TIME_OUT_PLUGIN, value);
  }
  static getTimeOutPlugIn(): string | null {
    return this.getLocalStorageItem(LOCAL_STORAGE_NAME.TIME_OUT_PLUGIN);
  }
  static clearTimeOutPlugIn(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.TIME_OUT_PLUGIN);
  }

  /**
   * Set and Get to local storage
   * a TimeOutActivate.
   */
  static setTimeOutActivate(value: string): void {
    this.setLocalStorageItem(LOCAL_STORAGE_NAME.TIME_OUT_ACTIVATE, value);
  }
  static getTimeOutActivate(): string | null {
    return this.getLocalStorageItem(LOCAL_STORAGE_NAME.TIME_OUT_ACTIVATE);
  }
  static clearTimeOutActivate(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_NAME.TIME_OUT_ACTIVATE);
  }
}

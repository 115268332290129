import axios from 'axios';
import {
  AMPLITUDE_CONSTANTS,
  API_CACHE_TYPE,
  API_CONTENT_TYPE,
  API_CREDENTIALS,
  API_METHODS,
  API_MODE,
  vehicleProgramInformation,
} from '@common/enums';
import { AmplitudeObj, AmplitudeServicePayload } from '@common/interfaces/amplitudeData.interface';
import LocalStorageUtils from '@common/utilities/localStorage';
import { APICallOptions } from '@common/interfaces/apiCalls.interface';
import { getAppLanguage } from '@common/utilities/getAppLanguage';
import { createAmplitudeObject } from './../utilities/amplitudeHelper';
import BaseService from './baseService';
import { v4 as uuidv4 } from 'uuid';

export default class AmplitudeService extends BaseService {
  static AMPLITUDE_API_URL: string | any = process.env.REACT_APP_HMPC_API_AMPLITUDE_API;
  static HMPC_API_BACKEND: string | any =
    process.env.REACT_APP_HMPC_API_BASEPATH + process.env.REACT_APP_HMPC_API_BACKEND;

  async postAmplitudeData(amplitudeObj: AmplitudeObj): Promise<any> {
    const traceID: string = uuidv4();
    const body: AmplitudeServicePayload = {
      ...amplitudeObj,
      vinNumber: LocalStorageUtils.getVinNumber().replace(/"/gi, ''),
      language: getAppLanguage(),
      platform: AMPLITUDE_CONSTANTS.CHARGE_ASSIST,
      deviceType: vehicleProgramInformation[LocalStorageUtils.getDeviceType()],
    };

    const AmplitudeBody = createAmplitudeObject(body);
    const options: APICallOptions = {
      method: API_METHODS.POST,
      mode: API_MODE.CORS,
      cache: API_CACHE_TYPE.NO_CACHE,
      credentials: API_CREDENTIALS.SAME_ORIGIN,
      headers: {
        Accept: API_CONTENT_TYPE.JSON,
        'Content-Type': API_CONTENT_TYPE.JSON,
        'x-correlation-id': traceID,
        url: AmplitudeService.AMPLITUDE_API_URL,
      },
      body: JSON.stringify(AmplitudeBody),
    };
    options.headers.mode = options.mode;
    if (LocalStorageUtils.getVinNumber()) {
      axios.post(AmplitudeService.HMPC_API_BACKEND, options.body, options);
    }
  }
}

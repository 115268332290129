// Some utility functions borrowed from
// https://github.ford.com/SYNC/menlo-core/tree/master/menlo-sdk/src/mqttApi

const ProtoBuf = require('protobufjs');
const topicTablesArray = {
  fileName: '',
};

const _topicTableMap = new Map();
const _topicDefPath = 'hmi-mqtt/dist/topic-tables';
const _protoPath = 'hmi-mqtt/dist/proto';
const validTopicTypes = new Map([
  ['value', null],
  ['event', null],
  ['update', null],
]);

function indexOfTypeInTopicComponents(topicComponents: string[]): number {
  for (let i = 0; i < topicComponents.length; ++i) {
    if (validTopicTypes.has(topicComponents[i])) {
      return i;
    }
  }
  throw new Error(
    `${topicComponents.join(
      '/',
    )} does not follow convention {endpoint}/{domain}/{subdomain (optional)}/{type}/{function}`,
  );
}
function getTopicTableFilename(topic: string): string {
  const topicComponents: string[] = topic.replace(/[<>]/g, '').split('/');
  const topicTypeIndex: number = indexOfTypeInTopicComponents(topicComponents);
  return topicComponents.slice(0, topicTypeIndex).join('_') + '.json';
}

function fetchJson(uri: any) {
  return require('hmi-mqtt/dist/topic-tables/' + topicTablesArray['fileName']);
}

function _fetchTopicTable(topic: string) {
  const filename = getTopicTableFilename(topic);
  let topicTable = _topicTableMap.get(filename);

  if (topicTable != null) {
    return topicTable;
  }
  topicTablesArray['fileName'] = `${filename}`;
  const uri = `${_topicDefPath}/${filename}`;
  const data = fetchJson(uri);
  if (typeof data === 'object') {
    topicTable = data;
    _topicTableMap.set(filename, topicTable);
    return topicTable;
  } else {
    throw new Error('Unexpected topic table data');
  }
}

function _fetchTopicDefinition(topic: any) {
  _fetchTopicTable(topic);
  const topicTable = _fetchTopicTable(topic);
  const topicDefinition = topicTable[topic];
  if (topicDefinition == null && topicDefinition !== undefined) {
    throw new Error(`Missing topic definition for ${topic}`);
  } else {
    return topicDefinition;
  }
}

function _getReflectionObjectForTopic(topic: any) {
  const topicDefinition = _fetchTopicDefinition(topic);
  const protoFile = require('hmi-mqtt/dist/proto/' + `${topicDefinition.protofile}`);
  const root = new ProtoBuf.Root.fromJSON(protoFile);
  try {
    if (topicDefinition.message == null) {
      throw new Error('Undefined message in topic definition');
    }
    return root.lookupType(topicDefinition.message);
  } catch (error) {
    throw new Error(`Could not load type "${String(topicDefinition.message)}": ${error}`);
  }
}

export function decodePayloadFromMessage(message: any): string | number {
  return _getReflectionObjectForTopic(message.destinationName).decode(
    new Uint8Array(message.payloadBytes),
  ).value;
}
